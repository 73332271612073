import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApiService } from '../app.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxSpinnerService } from 'ngx-spinner';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-newsletter',
  templateUrl: './newsletter.component.html',
  styleUrls: ['./newsletter.component.css']
})
export class NewsletterComponent implements OnInit {

  constructor(private api: ApiService, private snackBar: MatSnackBar, private spinner: NgxSpinnerService, private title: Title) { }

  ngOnInit(): void {
    this.title.setTitle('Εγγραφή στο newsletter' ); 
  }

  public async send(form: NgForm) {

    if (form.valid) {
      this.spinner.show();
      const addContact = await this.api.addContact(form.value).toPromise();
      if (addContact.message) {
        form.reset();
  
        setTimeout(()=>{                           
          this.spinner.hide()
          this.snackBar.open(
            'Εγγραφήκατε στο newsletter επιτυχώς!',
            'Το κατάλαβα!',
            {
              duration: 5000,
              panelClass: ['snackBar', 'snackBarSuccess'],
            }
          );
        }, 3000);
        
      }
    this.spinner.hide();
  }
}
}
